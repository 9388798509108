
@media (max-width: 768px) {
  .fieldParent {
    flex-direction: column;
    height: auto;
  }
  
  .fieldCourseParent {
    flex-direction: column;
    height: auto;
    padding-top: 5%;
  }

  .fieldChildImage {
    padding: 0 2%;
    padding-top: 7%;
  }
}

.courseSelectParent {
  display: inline-flex;
  flex-direction: row;
}

.MuiTableBody-root {
  overflow: auto;
}

.list {
  margin-left: 20px;
  margin-top: 40px;

}

.errorMsg {
  color: #d32f2f;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  font-size: 0.85rem !important;
  line-height: 1.66 !important;
  letter-spacing: 0.03333em !important;
  text-align: left;
  margin-top: 4px !important;
  margin-right: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
}