.districtNameMainBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

.districtNameBox {
    display: flex;
    flex-direction: row;
}

.districtNameBox Box {
    font-size: 40px;
}

.logoStyle {
    width: 96px;
}

.districtDashboardIcons {
    font-size: 40px !important;
    margin: 5px;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.calenderIcon {
    background: #f83e29;
}

.infoIcon {
    background: #2096f3;
}

.AccountBoxIcon {
    background: #f86029;
}

.LicenseStatusBox {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
}

.statusColor {
    border-radius: 100% !important;
    height: 18px;
    width: 18px;
    display: inline-flex;
}

.dashboardCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.cardBoxStyle {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
    /* display: flex;
    flex-direction: row;
    height: 80px;
    width: 23%;
    margin: 20px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 1px 15px;
    /* margin: 10px; */
    /* position: relative;
    min-width: 0;
    word-wrap:normal;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125); */
}

.cardDetailsStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.cardIconStyle {
    position: absolute;
    left: 93%;
    bottom: 20%;
    border-radius: 4px;
    font-size: 40px !important;
    color: #fff;
    width: 45px !important;
    height: 45px !important;
    padding: 8px;
    /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
}

.numberBoxStyle {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5 !important;
    font-size: 2.0736rem !important;
    color: #0e1318;
}

.textBoxStyle {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    line-height: 1.2;
    font-size: 0.8333333333rem;
    color: #0e1318;
}

.LineStyle {
    width: 75%;
    padding: 0;
    margin: 5px 0px;
    height: 3px;
}

.titleCustomHeader {
    color: #000 !important;
    font-family: "Manrope" !important;
    font-size: 1.25rem !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

.iconCustomHeader {
    position: relative;
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 0.5rem;
    background: #0D7FD2;
    cursor: pointer;
}

.iconWrapperCustomHeader {
    display: flex;
    gap: 0.625rem;
}

.statusCustomHeader {
    width: 0.96888rem;
    height: 0.96888rem;
    position: absolute;
    top: -0.25rem;
    right: -0.25rem;
    border-radius: 100%;
}

.activeStatus {
    background: #49CF72;
}

.notActiveStatus {
    background: #ff1100;
}

.customWelcomeCard {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    padding-left: 1rem;
    align-items: center;
    border-radius: 0.75rem;
    border: 1px solid #DFDFDF;
    background: #FFF;
    margin: 1.5rem 1.5rem;
    /* z-index: 999999; */
}

.customWelcomeText1 {
    color: #585858;
    font-family: "Manrope";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    /* line-height: normal; */
}

.customWelcomeText2 {
    color: #2E2E2E;
    font-family: "Manrope";
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}

.customWelcomeText2License {
    color: #49CF72;
    font-family: "Manrope";
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}

.customWelcomeText2LicenseR{
    color: #cf4949;
    font-family: "Manrope";
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
}

.customWelcomePhase1Wrapper {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
}

.customWelcomePhase1 {
    height: 3.37rem;
}

.leaderBoard {
    height: 100% !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: auto;
}

.customWelcomePhase2 {
    display: flex;
    gap: 1rem;
    height: 3.37rem;
}

.customDashboardCardWrapper {
    display: flex;
    align-items: center;
}

.customDashboardCardCount {
    color: #FFF;
    font-family: "Inter";
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.02119rem;
    background: #15135E;

    display: flex;
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
}

.customDashboardCardLabel {
    background: #FFF;
    color: #15135E;
    text-align: center;
    font-family: Inter;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.441rem;
    letter-spacing: 0.03275rem;
    padding: 0 3rem;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    border-radius: 0rem 0.75rem 0.75rem 0rem;
    border: 1px solid #DFDFDF;
    height: 3.37rem;
}