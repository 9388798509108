.ESBottomSection {
    background-color: rgb(222, 220, 220);
    height: 200px;
    width: 100%;
    border-radius: 5px;
}
                
.ESBottomSection-Top {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    padding: 20px 0px;
    justify-items: center;
}

.ESBottomSection-Bottom {
    width: 230px;
    height: 40px;
    gap: 10px;
    justify-items: right;
    margin-right: 45px;
    margin-top: 20px;
}

.buttonContainer {
    width: 230px;
    height: 40px;
    display: grid;
    float: right;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-right: 45px;
    margin-top: 25px;
}

.button {
    border-radius: 6px;
    border: 0;
    color: white;
}

.cancel {
    background-color: #6a6a6a;
}

.cancel:hover{
    background-color: #7f7f7f;
}

.update {
    background-color: #f2ac20;
}

.update:hover {
    background-color: #ce931e;
}

input[type="date"]{
    width: 95%;
    border-color: #75787b;
    border-radius: 3px;
    border-width: 2px;
    fill:darkviolet;
    font-size: 18px;
    padding: 5px;
}
