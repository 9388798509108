
@media (max-width: 768px) {
  .fieldParent {
    flex-direction: column;
    height: auto;
  }
  .fieldCourseParent {
    flex-direction: column;
    height: auto;
    padding-top: 5%;
  }

  .fieldChildImage {
    padding: 0 2%;
    padding-top: 7%;
  }
}

.courseSelectParent {
  display: inline-flex;
  flex-direction: row;
}

.MuiTableBody-root {
  overflow: auto;
}

.bulkMain {
  padding: 5%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
