.layoutWrapper {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.layoutMenuList {
    height: 100%;
    background: #15135E;
    box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.layoutMainContent {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

/* CustomBrandName STARTS */

.customBrandWrapper {
    position: relative;
    display: flex;
    align-items: center;
    height: 6.88rem;
    width: 100%;
    border-bottom: 1px solid #262474;
}

.newLogoStyle {
    width: 5.49013rem;
    margin-left: 1.31rem;
}

.newLogoStyleMobile {
    width: 4.5rem;
    padding: 0 .5rem;
}

.brandWrapper {
    margin-left: 0.61rem;
    height: 3.75rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.brandText1 {
    color: #FFF;
    font-family: "Lucida Console";
    font-size: 1.22425rem;
    font-style: normal;
    font-weight: 400;
    line-height: 80%;
    text-transform: capitalize;
}

.brandText2 {
    color: #FFF;
    font-family: "Lucida Console";
    font-size: 1.63231rem;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.menuToggle {
    position: absolute;
    right: -0.6561rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.3125rem;
    height: 2.25rem;
    background: #FFB900;
    border-radius: 1rem;
    cursor: pointer;
    z-index: 99;
}

/* CustomBrandName ENDS */

/* CustomMenuList STARTS */

.customMenuStyle {
    width: 100%;
    height: 3.75rem;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.menuItemStyle {
    color: #FFF;
    font-family: "Manrope";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.00875rem;
}

.currentMenuItemStyle {
    color: #000;
    font-family: Manrope;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.00875rem;
}

.customMenuIconStyle {
    width: 4rem;
    flex-shrink: 0;
    fill: #FFF;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
}

.currentMenuIconStyle {
    width: 4rem;
    flex-shrink: 0;
    fill: #FFF;
    background-blend-mode: multiply;
    display: flex;
    align-items: center;
    justify-content: center;
}

.customMenuIconStyle>svg {
    fill: #FFF;
}

.currentMenuIconStyle>svg {
    fill: #000;
}

/* CustomMenuList ENDS */


/* -------------------Font Size and Font Family starts ------------------- */
/* body{
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important;
} */

/* Table */

/* Table Title */
.MuiToolbar-root div div h6 {
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; */
    font-size: 20px !important;
    font-weight: bolder;
}

/* Table - All header style */
.MuiTableCell-head {
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; */
    font-size: 16px !important;
    font-weight: bold;
    text-transform: none;
    min-width: 0;
    margin-left: -8px;
    margin-right: 8px;
    padding-left: 8px;
    padding-right: 8px;
}

/* Table - Action */
.MuiTableCell-head div {
    /* font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif !important; */
    font-size: 16px !important;
    font-weight: bold;
}


/* Grid style */

/* .cds--css-grid {
    padding-inline: 0 !important;
}

.cds--css-grid-column {
    margin-inline: 0 !important;
} */

/* -------------------Font Size and Font Family Ends ------------------- */
.RootStyle {
    position: absolute;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.SideMainStyle {
    display: flex;
    flex-direction: row;
    overflow: show;
    position: fixed;
    width: 100%;
    height: 100%;
}

#NavStyle {
    /* z-index: 1; */
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding-left: 0;
    height: 56px;
    /* background-color: #2d8f3c; */
    background: #2d8f3c;
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #0575E6, #2d8f3c);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #0575E6, #2d8f3c);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: flex;
    justify-content: space-between;
}

.NavBrandMenuStyle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 6.87rem;
    /* border-bottom: 3.75rem solid #15135E; */
    border-bottom: 1px solid #262474;
}

.brandname {
    width: 225px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
    color: #000;
}

.ShrinkbrandWrapper {
    margin-left: 0.61rem;
    height: 3.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.menuTextStyle {
    font-family: "Manrope";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.00875rem;
}

.menuStyle {
    color: #fff;
    cursor: pointer;
    margin: 0.25rem 0.5rem;
    border-radius: 0.2rem;
    margin-left: 26%;
}

.sideNavStyle {
    padding-top: 56px;
}

/* MenuIcon */

.menuIconStyle {
    width: 1.3125rem;
    height: 2.25rem;
    background: #FFB900;
    display: flex;
    margin-left: 20%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    right: -0.65625rem;
}

.rightMainMenu {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    position: relative;
}

.MobileViewDraweer {
    display: none;
}

/* Hide Side Menu in Mobile View */
@media (max-width: 960px) {
    /* .menuList {
        display: none !important;
    }

    .menuIconStyle {
        display: none !important;
    } */

    /* RightSide Menu Toggle */
    .rightMainMenu {
        position: fixed;
    }

    .MobileViewDraweer {
        display: flex !important;
        margin-left: 20%;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.BreadcrumbName {
    display: inline-block;
    text-transform: capitalize;
}

@media (max-width: 960px) {
    .BreadcrumbName {
        display: none;
    }
}

.parentBoxStyle {
    width: 100%;
    overflow: auto;
    padding: 0 15px;
    margin-bottom: 50px;
}

.HeaderBoxResponsive {
    position: sticky;
    top: 0;
    z-index: 10;
}

.HeaderBoxResponsive:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: height 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    backdrop-filter: blur(2px);
    z-index: -1;
    left: 0;
    top: 0;
    /* box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 33%), 0px 0px 0px 0px rgb(0 0 0 / 33%), 0px 0px 17px 0px rgb(0 0 0 / 12%); */
}

.createButtonParent {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media (max-width: 768px) {
    .createButtonParent {
        padding: 12px;
    }
}

.districtTitle {
    font-size: 24px !important;
}

.LessonBotAdmin {
    padding: 20px;
    height: 100%;
    background-color: #e3f2fd;
}

.LessonBotBG {
    display: block;
    width: 100%;
    height: 100%;
}

.LessonBotHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin: 1.48rem 0;
    border-radius: 0.75rem;
    border: 1px solid #DFDFDF;
    background: #FFF;
}

.CreateAssignmentWrapper {
    width: 100%;
    overflow: auto;
    padding: 0 1.5rem;
}

.tableParent {
    height: 85%;
    width: 100%;
    border-radius: 12px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
}

.LessonBotContent {
    position: relative;
    flex-grow: 1;
    padding-bottom: 2%;
    height: 100%;
}

/* create content */
.createDomainParent {
    height: 100%;
    width: 100%;
    border-radius: 12px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    overflow: auto;
}

.fieldParent {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: auto;
}

.fieldParentSubHeading {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10%;
    margin-top: 2%;
}

.sub-heading {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: 2% 0;
}

.fieldChild {
    position: relative;
    width: 100%;
    padding: 2%;
}

.createFieldChild {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.createButtonChild {
    position: relative;
    padding: .5%;
}

.fieldStyle {
    width: 100%;
}

.fieldCourseParent {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30%;
}

.fieldChildImage {
    position: relative;
    width: 100%;
    padding: 2%;
    align-self: center;
    justify-content: flex-start;
}

@media (max-width: 768px) {
    .fieldParent {
        flex-direction: column;
        height: auto;
    }

    .fieldCourseParent {
        flex-direction: column;
        height: auto;
        padding-top: 5%;
    }

    .fieldChildImage {
        padding: 0 2%;
        padding-top: 7%;
    }
}

@media (max-width: 960px) {
    .bottomSpace {
        height: 60px
    }
}

.gridBoxStyle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 70px;
    padding-top: 40px;
}

.gridBoxStyle1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

/* Dashboard Starts */
.gridContainer1 {
    padding: 15px 0px;
    margin: 20px;
    width: 100%;
}

.imgBoxLayout {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, .12);
    box-shadow: 0 2px 0 rgb(0 0 0 / 12%);
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer;
}

.imgStyleLayout {
    width: 20px;
    height: 20px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
/* input[type=number] {
    -moz-appearance: textfield;
} */

/* levels Master style */

.headerTrStyle,
.bodyBoxStyle {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.5rem;
}

.headerThStyle,
.bodyinnerBoxStyle {
    width: 33%;
    text-align: center;
}

thead tr th {
    z-index: 0 !important;
}

.MuiDrawer-docked .MuiDrawer-paper {
    /* overflow-x: hidden !important; */
    background: #15135E;
    box-shadow: 2px 0px 0px 0px rgba(0, 0, 0, 0.05);
}

.th-border-style {
    border-bottom: 1px solid rgb(224, 224, 224);
}

/* manage district license */

.button-Style {
    display: flex;
    flex-direction: row;
}

.fileuploadStyle>label {
    width: 30rem !important;
    height: 17rem !important;
    border-radius: 0.75rem;
    border: 1px dashed #0D7FD2 !important;
    cursor: pointer;
    margin: 1rem;
}

.bulkUploadPhase2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30rem !important;
}

.cardData1 {
    display: flex;
    justify-content: space-between;
    margin: 10px 15px;
    font-weight: bold;
    font-size: 14px;
}


.cardHeading {
    display: flex;
    justify-content: center;
}

.HeaderContent {
    display: flex;
    justify-content: space-between;
}

.heightInc {
    height: 5%;
}

.MuiPopover-paper {
    right: 0;
    left: unset;
    width: 20%;
}

.districtAction {
    display: flex;
}

.fieldLabel {
    color: #525252;
    font-family: "Manrope";
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: 0.02rem;
    margin-bottom: 1rem;
}