.schoolUsageListMain {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 5%;
}

.schoolUsageListHead {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 60%);
    height: calc(100% - 60%);
    /* background-color: #e2f6e5 !important; */
}

.numberStyle {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5 !important;
    font-size: 1.0736rem !important;
    font-weight: bolder;
    color: #0e1318;
}

@media (max-width:720px) {
    .schoolUsageListHead {
        width: calc(100% - 10%);
    }
}

.districtNameMainBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
}

.districtNameBox {
    display: flex;
    flex-direction: row;
}

.districtNameBox Box {
    font-size: 40px;
}

.logoStyle {
    width: 96px;
}

.districtDashboardIcons {
    font-size: 40px !important;
    margin: 5px;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
}

.calenderIcon {
    background: #f83e29;
}

.infoIcon {
    background: #2096f3;
}

.groupIcon{
    background: #f86029;
}

.LicenseStatusBox {
    display: flex;
    flex-direction: row;
    padding: 10px;
    justify-content: flex-end;
    align-items: center;
}

.statusColor {
    border-radius: 100% !important;
    height: 18px;
    width: 18px;
    display: inline-flex;
}

.dashboardCards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.cardBoxStyle {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    position: relative;
    /* display: flex;
    flex-direction: row;
    height: 80px;
    width: 23%;
    margin: 20px;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 35%) 0px 1px 15px;
    /* margin: 10px; */
    /* position: relative;
    min-width: 0;
    word-wrap:normal;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125); */
}

.cardDetailsStyle {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.cardIconStyle {
    position: absolute;
    left: 93%;
    bottom: 20%;
    border-radius: 4px;
    font-size: 40px !important;
    color: #fff;
    width: 45px !important;
    height: 45px !important;
    padding: 8px;
    /* box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px; */
}



.numberBoxStyle {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5 !important;
    font-size: 2.0736rem !important;
    color: #0e1318;
}

.textBoxStyle {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 500;
    line-height: 1.2;
    font-size: 0.8333333333rem;
    color: #0e1318;
}

.LineStyle{
    width: 75%;
    padding: 0;
    margin: 5px 0px;
    height: 3px;
}

/* Dashboard Starts */
.gridContainer {
    padding: 15px 0px;
    margin-top: 15px;
    margin-left:5px;
    /* background-color: aquamarine; */
    width: 100%;
}

.performance {
    background-color: white;
    margin: 20px 5px;
    border-radius: 10px;
    border-color: rgb(218, 218, 218);
    border-style: solid;
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.containerTitle{
    margin-left: 15px;
    padding: 0;
    margin-bottom: 0;
}

.grid {
    /* background-color: aquamarine; */
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    padding: 0;
    margin: 0;
    /* background-color: brown; */
    width: 100%;
}


.card {
    height: 90px;
    width: auto;
    margin: 10px;
    /* padding: 5px; */
    /* text-align: center; */
}

.cardContent {
    display: inline-flex;
}

.cardText {
    width: 80%;
    height: max-content;
    margin-left: 20px;
    margin-top: 17px;
}

.cardTitle{
    padding: 0;
    margin: 0;
}

.cardValues{
    font-size: 24px;
    font-weight: bold;
}
.iconContainer{
    background-color: darkseagreen;
    height: fit-content;
    position:absolute;
    border-radius: 4px;
    bottom: 20px;
    right: -20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pGrid{
    /* background-color: aquamarine; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    grid-auto-rows: minmax(100px, auto);
    padding: 0px 10px; 
}

.pIconContainer{
    height: fit-content;
    margin-top: 13px;
    margin-right: 10px;
}

.line {
    width: 75%;
    padding: 0;
    margin: 5px 0px;
    height: 1px;
}

.icon {
    height: '7vh';
    width: '7vh';
    color: 'white';
}

/* Dashboard Ends */

.center{
    position:relative;
    left:50px;
}

.headIcon{
    margin-bottom: 120px;
}

.headContainer{
   
    height:max-content;
    position:fixed;
    border-radius: 5px;
    top:100px;
    right:80px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: lightblue;
}

.headContainer2{
    
    height:max-content;
    position:fixed;
    border-radius: 5px;
    top:100px;
    right:30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: lightsalmon;
    
;}

.studentCard {
    height: 280px;
    width: 300px;
    margin-top: 50px;
    margin-left: 50px;
    
}

.cardHead {
    background-color: rgb(146, 231, 146,0.5);
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cardData{
    margin-top: 10px;
    margin: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align:left;
}


