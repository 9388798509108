.gridContainer {
    /* padding: 15px 0px; */
    width: 100%;
}

.performance {
    background-color: white;
    margin: 20px 5px;
    border-radius: 10px;
    border-color: rgb(218, 218, 218);
    border-style: solid;
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.containerTitle{
    margin-left: 15px;
    padding: 0;
    margin-bottom: 0;
}

.grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    grid-auto-rows: minmax(100px, auto);
    padding: 0;
    margin: 0;
    width: 100%;
}


.card {
    height: 90px;
    width: auto;
    margin: 10px;
}

.cardContent {
    display: inline-flex;
}

.cardText {
    width: 80%;
    height: max-content;
    margin-left: 20px;
    margin-top: 17px;
}

.cardTitle{
    padding: 0;
    margin: 0;
}

.cardValues{
    font-size: 24px;
    font-weight: bold;
}
.iconContainer{
    background-color: darkseagreen;
    height: fit-content;
    position:absolute;
    border-radius: 4px;
    bottom: 20px;
    right: -20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.pGrid{
    /* background-color: aquamarine; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0px;
    grid-auto-rows: minmax(100px, auto);
    padding: 0px 10px; 
}

.pIconContainer{
    height: fit-content;
    margin-top: 13px;
    margin-right: 10px;
}

.line {
    width: 75%;
    padding: 0;
    margin: 5px 0px;
    height: 1px;
}

.icon {
    height: '7vh';
    width: '7vh';
    color: 'white';
}

/* Dashboard Ends */

.center{
    position:relative;
    left:50px;
}

.headIcon{
    margin-bottom: 120px;
}

.headContainer{
   
    height:max-content;
    position:fixed;
    border-radius: 5px;
    top:100px;
    right:80px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: lightblue;
}

.headContainer2{
    
    height:max-content;
    position:fixed;
    border-radius: 5px;
    top:100px;
    right:30px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    background-color: lightsalmon;
    
;}

.studentCard {
    height: 280px;
    width: 300px;
    margin-top: 50px;
    margin-left: 50px;
    
}

.cardHead {
    background-color: rgb(146, 231, 146,0.5);
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.cardData{
    margin-top: 10px;
    margin: 10px;
    font-weight: bold;
    font-size: 20px;
    text-align:left;
}

.adminTitleBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

@media (max-width: 760px){
    .grid{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(100px, auto);
        padding: 0;
        margin: 0;
        width: 100%;
    }
}

@media (max-width: 600px){
    .pGrid{
        /* background-color: aquamarine; */
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        grid-auto-rows: minmax(100px, auto);
        padding: 0px 10px; 
    }
}

@media (max-width: 450px){
    .grid{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: minmax(100px, auto);
        padding: 0;
        margin: 0;
        width: 100%;
    }
}