.schoolUsageListMain {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 5%;
}

.schoolUsageListHead {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: calc(100% - 60%);
    height: calc(100% - 60%);
    /* background-color: #e2f6e5 !important; */
}

.numberStyle {
    font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5 !important;
    font-size: 1.0736rem !important;
    font-weight: bolder;
    color: #0e1318;
}

@media (max-width:720px) {
    .schoolUsageListHead {
        width: calc(100% - 10%);
    }
}