.bottomContainer {
    height: auto;
    width: 100%;
    padding: 8px;
}

.tableContainer {
    width: auto;
    height: auto;
}

.lbSection {
    height: auto;
    width: 95%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 15px;
    margin: 30px 2%;
}

.lb-bottom {
    overflow: hidden;
    overflow-y: scroll;
}

.ClassPage {
    overflow: auto;
    width: 100%;
    display: block;
    padding: 0px;
}

/* Nav */
.wrapper {
    height: 62px;
    width: 100%;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    text-align: center;
}

.wrapper nav {
    position: relative;
    display: flex;
    padding: 15px;
}

.wrapper nav label {
    flex: 1;
    width: 100%;
    z-index: 1;
    position: relative;
    cursor: pointer;
}

.wrapper nav label a {
    position: relative;
    z-index: -1;
    color: #1d1f20;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
}

.wrapper nav label a i {
    font-size: 25px;
    margin: 0 7px;
}

.wrapper nav .tab {
    position: absolute;
    height: 84%;
    width: 33%;
    left: 0;
    top: 0;
    background: #2096f3;
    border-radius: 2px;
    transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.wrapper nav input {
    display: none;
}

.wrapper nav #minutes:checked~label.minutes a {
    color: #fff;
}

.wrapper nav #problems:checked~label.problems a {
    color: #fff;
}

.wrapper nav #levels:checked~label.levels a {
    color: #fff;
}

.wrapper nav #minutes:checked~.tab {
    left: 0%;
}

.wrapper nav #problems:checked~.tab {
    left: 35%;
}

.wrapper nav #levels:checked~.tab {
    left: 67%;
}

.dateContainer {
    width: 60%;
    height: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin: 0 2%;
}

.date {
    width: 50%;
    height: auto;
    padding: 10px;
}

.dateInput {
    width: 100%;
    margin-top: 5px;
}

.lbphase1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
}

.lbphase2 {
    display: flex;
    align-items: center;
    width: 55%;
    color: #424242;
    font-family: "Inter";
    font-size: 1.23288rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.93738rem;
    letter-spacing: 0.044rem;
}

.lbphase3 {
    flex-direction: column;
    width: 25%;
}

.numberlbBox {
    width: 2.818rem;
    height: 2.818rem;
    flex-shrink: 0;
    border-radius: 0.7045rem;
    background: linear-gradient(225deg, #41A7C4 0%, #3E72E8 100%);
    box-shadow: 0px 4.22697px 0px 0px #0000000d;

    color: #FFF;
    text-align: center;
    font-family: "Inter";
    font-size: 1.409rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.93738rem;
    letter-spacing: 0.044rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.numberlbBoxNotActive {
    color: #424242;
    text-align: center;
    font-family: "Inter";
    font-size: 1.409rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.93738rem;
    letter-spacing: 0.044rem;
}

.lbTextTitle {
    color: #424242;
    font-family: "Inter";
    font-size: 1.23288rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.93738rem;
    letter-spacing: 0.044rem;
}

.lbTextSubTitle {
    color: #424242;
    font-family: "Inter";
    font-size: 1.05675rem;
    font-style: normal;
    font-weight: 300;
    line-height: 107%;
    letter-spacing: 0.02981rem;
}

.lbBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    cursor: pointer;
    padding: 8px;
    border-radius: 1.32094rem;
    color: #424242;
    font-family: "Inter";
    font-size: 1.23288rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.93738rem;
    letter-spacing: 0.044rem;
    border-radius: 1.32094rem;
    border: 1.344px solid #D8D8D8;
    background: #FFF;
    margin: .35rem 0;
}

.lbBox:hover {
    /* transform: scale(1.01); */
    box-shadow: 0px 15px 28px rgb(0 0 0 / 10%), 0px 5px 12px rgb(0 0 0 / 8%);
    transition: all 300ms ease;
    background: #FFC82C;
}

.ConceptTable {
    display: flex;
    width: 100%;
    height: auto;
}

.totalDetails>div {
    color: #424242;
    text-align: center;
    font-family: "Inter";
    font-size: 1.23288rem;
    font-style: normal;
    font-weight: 400;
    line-height: 107%;
    letter-spacing: 0.02981rem;
}

.totalDetails{
    display: flex;
    align-items: center;
}

@media (max-width: 450px) {
    .lbBox {
        flex-direction: column;
    }

    .totalDetails {
        flex-direction: row !important;
    }
}

.chartStylePieHead {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.chartStylePie {
    display: flex;
    width: 50%;
}