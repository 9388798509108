.LessonBotContent {
  position: relative;
  flex-grow: 1;
  padding-bottom: 2%;
  height: 95%;
}

.fieldParentCU {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18%;
}

.fieldParentCURadioButton {
  width: 100%;
}

.fieldChildCU {
  position: relative;
  width: 100%;
  padding: 2%;
}

.fieldChildCUClass {
  position: relative;
  width: 100%;
  padding: 2%;
}

.fieldChildCURadioButton {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 2%;
}

.checkRadioStyle {
  width: 20%;
}

@media (max-width: 1200px) {
  .checkRadioStyle {
    width: 32%;
  }
}

@media (max-width: 525px) {
  .checkRadioStyle {
    width: 43%;
  }
}

@media (max-width: 415px) {
  .checkRadioStyle {
    width: 55%;
  }
}