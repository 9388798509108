.WeeklyReportSubText {
    padding: .75rem;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 21px !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-transform: capitalize;
}

.WRIconOnly {
    display: flex;
    width: 3.3125rem;
    height: 3.3125rem;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    border-radius: 0.5rem;
    border: 1px solid #15135E;
}

.WeeklyReportSubTextTitle {
    display: inline-flex;
    padding: 0.8125rem 1rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background: #FFB900;
    box-shadow: 0px 4px 12px 0px rgba(255, 185, 0, 0.30);
    color: #000;
    font-family: "Manrope";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    /* width: fit-content; */
    white-space: nowrap;
    line-height: normal;
}

.MuiIconButton-root:hover {
    background-color: transparent !important;
}

.WeeklyReportFromToDate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    width: 100%;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 21px !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    text-transform: capitalize;
}

.WeeklyReportBox1 {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 70%;
}

.WeeklyReportBox2 {
    display: flex;
    justify-content: flex-end;
    width: 30%;
}

.fromDate,
.toDate {
    margin-right: 5% !important;
}

.schoolLeaderboardTable {
    width: 100%;
    background-color: #fff;
}

.schoolLeaderboardTable .MP {
    background-color: #ffbf00;
    color: #ffffff;
    border: 1px solid black;
}

.schoolLeaderboardTable .PS {
    background-color: #71ad47;
    color: #ffffff;
    border: 1px solid black;
}

.schoolLeaderboardTable .GC {
    background-color: #ed7d32;
    color: #ffffff;
    border: 1px solid black;
}

.schoolLeaderboardTable .LM {
    background-color: #5b9bd5;
    color: #ffffff;
    border: 1px solid black;
}

.schoolLeaderboardTable thead {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
}

.schoolLeaderboardTable tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid;
}

.schoolLeaderboardTable tbody tr {
    width: 100%;
    display: flex;
}

.schoolLeaderboardTable tbody tr td {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    border: 1px solid;
}

.LBTabPrimary {
    display: inline-flex;
    padding: 0.8125rem 1rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    background: #FFB900;
    box-shadow: 0px 4px 12px 0px rgba(255, 185, 0, 0.30);
    color: #000;
    font-family: "Manrope";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    line-height: normal;
    margin-right: 1rem;
    cursor: pointer;
}

.LBTabSecondary {
    display: inline-flex;
    padding: 0.8125rem 1rem;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #000;
    background: transparent;
    box-shadow: 0px 4px 12px 0px rgba(255, 255, 255, 0.3);
    color: #000;
    font-family: "Manrope";
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    line-height: normal;
    margin-right: 1rem;
    cursor: pointer;
}