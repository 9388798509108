.ESBottomSection {
    height: 195px;
    width: 100%;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: inline-block;
}
                
.ESBottomSection-Top {
    display: grid;
    border-top-left-radius:5px;
    border-top-right-radius:5px;
    grid-template-columns: repeat(2, 1fr);
    background-color: white;
    gap: 0px;
    padding: 20px 0px;
    justify-items: center;
    height: 55%;
}

.ESBottomSection-Bottom{
    /* background-color: rgb(246, 244, 244); */
    background-color: white;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100px;
    border-radius: 1px;
    border-bottom-left-radius:5px;
    border-bottom-right-radius:5px;
    height: 45%;
}

.buttonContainer {
    width: 230px;
    height: 40px;
    display: grid;
    float: right;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    /* margin-right: 45px;
    margin-top: 20px; */
    margin: 2% 4%;
}

.button {
    border-radius: 6px;
    border: 0;
    color: white;
}

.cancel {
    background-color: #6a6a6a;
}

.cancel:hover{
    background-color: #7f7f7f;
}

.update {
    background-color: #f2ac20;
}

.update:hover {
    background-color: #ce931e;
}

input[type="date"]{
    width: 95%;
    border-color: #75787b;
    border-radius: 3px;
    border-width: 2px;
    fill:darkviolet;
    font-size: 18px;
    padding: 5px;
}
