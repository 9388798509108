.courseSelectParent {
  display: inline-flex;
  flex-direction: row;
}

.MuiTableBody-root {
  overflow: auto;
}

.bulkUploadHead {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.bulkupLoadSchool {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 2px dashed #b5cef2;
  border-radius: 5px;
  margin: 12px;
  width: 70%;
  height: 170px;
}

.fVfcRr {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.sc-hKMtZM {
  font-size: 1em !important;
  font-weight: 600 !important;
  color: #b5cef2 !important;
  letter-spacing: 0.02em !important;
  text-align: center !important;
}

/* File Type */
.file-types {
  display: none;
}

/* Hide Icon */
.sc-bczRLJ svg {
  display: none;
}

/* download sample file style */

.anchorStyle {
  display: flex;
  padding: 1.0625rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid #FFF;
  background: #0D7FD2;
  box-shadow: 0px 4px 12px 0px rgba(13, 127, 210, 0.30);
  color: #FFF;
  font-family: "Manrope";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.anchorStyle:hover {
  color: #fff;
  background: #0b89e3;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.bulkUploadInputWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
  position: relative;
  width: 100% !important;
}

.bulkUploadInputBox {
  position: relative;
  align-items: center;
  width: 30rem !important;
}