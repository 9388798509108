#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}

.justifyRow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.form {
  max-width: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  padding: 50px;
  color: black;
  width: 500px
}

.skeleton-parent {
  position: absolute;
  height: 100%;
  width: 100%;
}

.skeleton-child {
  position: relative;
  height: 100%;
}

.skeleton-body {
  display: flex;
  flex-direction: row;
  position: relative;
}

.skeleton-menu {
  position: relative;
  width: 15%;
}

.skeleton-content {
  position: relative;
  width: 85%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.container {
  position: relative;
  width: 70%;
  height: 400px;
  margin: 20px auto;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  margin: 0;
  opacity: 0;
  transition: 1s ease-in-out;
  overflow: hidden;
}

.slide img {
  width: 100%;
}


.prev {
  left: 0;
}

.next {
  right: 0;
}

.dots_container {
  display: flex;
  margin: 5px auto;
  width: fit-content;
}

.dots {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #bdbdbd;
  margin: 4px;
}

.dots:hover {
  background: #696969 !important;
}

@media screen and (max-width:600px) {
  .slide {
    height: fit-content;
  }

  .container {
    height: 200px;
  }

  button {
    top: 50%;
  }
}

.carosuelFrame {
  height: calc(100% - 110.06px);
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.carosuel {
  display: flex;
  width: 70%;
  height: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 1.3635rem;
  background: linear-gradient(180deg, #1D1D1E 0%, #0E0D4B 80.85%);
  box-shadow: 0px 4.363px 12px 0px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  position: relative;
}

.caroselIcons {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.68175rem;
  flex-shrink: 0;
  border-radius: 34.08813rem;
  border: 1px solid #FFB900;
  background: #0F0E4C;
  cursor: pointer;
}

.boarding-left-arrow {
  position: absolute;
  left: 11%;
  top: 40%;
}

.boarding-right-arrow {
  position: absolute;
  right: 11%;
  top: 40%;
}

.carouselArrow {
  width: 1.63625rem;
  height: 1.63625rem;
  flex-shrink: 0;
  fill: #FFB900;
}

.carosuelTop {
  height: 40%;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 24.5rem;
  position: relative;
}

.carosuelBottom {
  height: 60%;
  display: flex;
  align-items: center;
}

.carosuelText {
  color: #FFF;
  text-align: center;
  font-family: "Manrope";
  font-size: calc(2.18163rem - .9rem);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 85%;
  margin-bottom: 1rem;
}

.carosuelSubText {
  color: rgba(255, 255, 255, 0.60);
  text-align: center;
  font-family: "Manrope";
  font-size: calc(1.25rem - .4rem);
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.035rem;
  padding: 0 2rem;
}

.lottieParent {
  width: 100%;
  transform: scale(1.55);
}

.boarding-container-area {
  width: 75%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.brand-label-container {
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 0px 0px 0px 59px; */
}

.boarding-container-1 {
  width: 100%;
  height: 78%;
  /* padding: 35.24px 60px 30px 60px; */
}

.boarding-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #4922fd;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 4px 8px 0 rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.boarding-label-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 101px 50px 102px;
}

.boarding-label {
  color: #FFF;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
}

.boarding-anim-container {
  width: 100%;
  overflow: hidden;
}

.boarding-pagination-container {
  position: absolute;
  bottom: 2.37rem;
  height: 0.625rem;
  ;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.pagination-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  border: white 1px solid;
  margin-right: 10px;
}



@keyframes onBoardLeft {
  0% {
    right: 0;
    top: 0;
  }

  25% {
    right: 25%;
    top: 0;
  }

  50% {
    right: 40%;
    top: 0;
  }

  75% {
    right: 50%;
    top: 0;
  }

  100% {
    right: 100%;
    top: 0;
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(-300px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes moveToLeft {
  0% {
    transform: translateX(300px);
  }

  100% {
    transform: translateX(0px);
  }
}

@media (max-width: 1200px) {
  /* .carosuelTop {
    height: 50%;
  }

  .carosuelBottom {
    height: 50%;
  } */
}