.Parent_404 {
  position: absolute;
  height: 100%;
  width: 100%;
}

.Child_404 {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  font-family: 'Tomorrow', sans-serif;
  height: 100vh;
  background-image: linear-gradient(to top, #2e1753, #1f1746, #131537, #0d1028, #050819);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* 
.four_zero_four_bg {
  background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  object-fit: contain;
  background-position: center;
}

.text-404 {
  position: relative;
  top: 10%;
  left: 45%;
  font-weight: bolder !important;
}

.T1 {
  position: relative;
  top: 55%;
  left: 91%;
  transform: translate(-50%, -50%);
  font-family: fantasy !important;
}
.T2 {
  position: relative;
  top: 55%;
  left: 85%;
  transform: translate(-50%, -50%);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif !important;
}
.button-404 {
  position: relative;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
} */


.text {
  position: absolute;
  top: 10%;
  color: #fff;
  text-align: center;
}

h1 {
  font-size: 50px;
}

.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: #fff;
  right: 0;
  animation: starTwinkle 3s infinite linear;
}

.astronaut img {
  width: 100px;
  position: absolute;
  top: 55%;
  animation: astronautFly 6s infinite linear;
}

@keyframes astronautFly {
  0% {
    left: -100px;
  }

  25% {
    top: 50%;
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(45deg);
    top: 55%;
  }

  75% {
    top: 60%;
    transform: rotate(30deg);
  }

  100% {
    left: 110%;
    transform: rotate(45deg);
  }
}

@keyframes starTwinkle {
  0% {
    background: rgba(255, 255, 255, 0.4);
  }

  25% {
    background: rgba(255, 255, 255, 0.8);
  }

  50% {
    background: rgba(255, 255, 255, 1);
  }

  75% {
    background: rgba(255, 255, 255, 0.8);
  }

  100% {
    background: rgba(255, 255, 255, 0.4);
  }
}